import { httpClient } from '../components/http-client/http-client';
import { apiConstants } from "./api-constants";

var apiTime = {

    fetchSessionsForDate(date, withSlots) {
        let params = {
            date: date,
            include_slots: withSlots,
        };
        return httpClient.get(apiConstants.fetchSessionsForDate, { params: params });
    },

    fetchAllSessions() {
        return httpClient.get(apiConstants.fetchSessionsForDate);
    },

    fetchAllSessionsWithDayOfWeekSlots() {
        let params = {
            include_days_of_week: true
        };
        return httpClient.get(apiConstants.fetchSessionsForDate, { params: params });
    },

    createSession(name, daysOfWeek) {
        let params = {
            name: name,
            days_of_week: daysOfWeek
        };
        return httpClient.post(apiConstants.reservations.createSession, params);
    },

    deleteSession(sessionId) {
        return httpClient.delete(apiConstants.reservations.deleteSession + "/" + sessionId);
    },

    fetchSlots(sessionId) {
        return httpClient.get(apiConstants.fetchSessions + "/" + sessionId + "/slot");
    },

    fetchAllSlots() {
        let params = {
            start: 0,
            length: 0,
            sort_column: 'serial_number',
            sort_order: 'ASC'
        };
        return httpClient.get(apiConstants.reservations.slots, { params: params });
    },

    updateDayOfWeekSlots(sessionId, dayOfWeek, startSlotId, endSlotId) {
        let params = {
            start_slot_id: startSlotId,
            end_slot_id: endSlotId
        };
        return httpClient.put(
            apiConstants.reservations.updateDayOfWeekSlots + `/${sessionId}/day-of-week/${dayOfWeek}`,
            params);
    },

    databaseToLocalTime(dbTime) {

    },

    parseTime(s) {
        var part = s.match(/(\d+):(\d+):(\d+)(?: )?(am|pm)?/i);
        var hh = parseInt(part[1], 10);
        var mm = parseInt(part[2], 10);
        var ss = parseInt(part[3], 10);
        var ap = part[4] ? part[4].toUpperCase() : null;

        if (ap === "AM") {
            if (hh == 12) {
                hh = 0;
            }
        }

        if (ap === "PM") {
            if (hh != 12) {
                hh += 12;
            }
        }

        return { hh: hh, mm: mm, ss: ss };
    },

    formatTime(t, format) {
        var time = this.parseTime(t);
        var now = new Date();

        now.setHours(time.hh, time.mm, time.ss);
        return moment(now).format(format);  // 'h:mm'
    },

    formatDate(date, format = "YYYY-MM-DD") {
        return moment(date).format(format);
    },

    // returns time in milliseconds
    // timeFrom, timeTo: hh:mm:ss format
    // if timeTo is later than timeFrom, returns negative numbers
    diffTime(timeFrom, timeTo) {
        timeFrom = this.parseTime(timeFrom);
        timeTo = this.parseTime(timeTo);
        var from = new Date();
        var to = new Date();

        from.setHours(timeFrom.hh, timeFrom.mm, timeFrom.ss);
        to.setHours(timeTo.hh, timeTo.mm, timeTo.ss);
        return moment(from).diff(to);
    },

    addTime(timeStart, addMilliseconds) {
        timeStart = this.parseTime(timeStart);
        return moment()
            .hours(timeStart.hh)
            .minutes(timeStart.mm)
            .seconds(timeStart.ss)
            .add(59 * 60 * 1000 + 59 * 1000, 'ms')
            .format("HH:mm:ss");
    },

    isToday(someDate) {
        const today = new Date();
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear();
    },

    getDayName(someDate) {
        return someDate.toLocaleDateString("en-IN", { weekday: 'short' });
    },

    getMonthAbbv(someDate) {
        return someDate.toLocaleString("en-IN", { month: "short" });
    },

    getDate(someDate) {
        return this.isToday(someDate) ? "Today" : someDate.getDate();
    }
};

export { apiTime };