(function ($) {

    $.fn.card = function(options)
    {
        var settings        = $.extend({}, $.fn.card.defaults, options);
        var $root           = {};
        var $frontFace      = {};
        var $backFace       = {};

        /**
         * Since the card's children are absolutely positioned, there's no way
         * to set the card's height to contain both children. It will always
         * be reported as 0. This js fixes that.
         */
        function setCardHeight()
        {
            var maxHeight   = 0;

            $frontFace.children().each(function(){
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
            });

            $backFace.children().each(function(){
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
            });

            $root.css("min-height", maxHeight);
        }
        /**
         *
         */
        function init()
        {
            $root           = this;
            $frontFace      = $root.find(".card-face.front");
            $backFace       = $root.find(".card-face.back");

            settings.onInit.call(this);
            $(window).on('resize', setCardHeight);
            setCardHeight.call(this);
        }

        /**
         *
         */
        $.fn.card.flip = function()
        {
            $root.toggleClass("flip");
        };

        // 'this' here is the jQuery object
        return this.each(function(){
            // 'this' here is the raw DOM element
            init.call($(this));
        });
    };

    /**
     * The defaults for the plugin
     */
    $.fn.card.defaults = {
        onInit: function(){}
    };

}(jQuery));

