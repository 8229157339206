(function ($) {

    $.fn.table_card = function(options)
    {
        var settings                    = {};
        var $card                       = {};
        var $frontFace                  = {};
        var $backFace                   = {};
        var $dataTable                  = {};
        var overloadFirstCol            = false;
        var overloadLastCol             = false;
        var $selectAll                  = {};
        var $newAction                  = {};
        var $batchActions               = {};
        var $batchMenu                  = {};
        var $btnBatchActions            = {};

        /**
         *
         */
        function addColDefForFirstCol() {

            var colDefFirstIndex = -1;

            // If the user has supplied columnDefs for column 0, we ignore ours
            if (options && options.columnDefs) {

                // Check if user supplied columnDef for first column
                colDefFirstIndex = options.columnDefs.findIndex(function(element){
                    return (element.targets === 0);
                });
            }

            // If caller did not supply columnDef for column 0, we'll use our own
            if (colDefFirstIndex === -1 && settings.table_card.actionButtons.selectAll) {
                settings.columnDefs.push({
                    targets: 0,
                    render:  function (data) {
                        return "<input class='select-row' type='checkbox' value='0'/>&nbsp;&nbsp;" + data;
                    }
                });
            }

            return (colDefFirstIndex === -1 && settings.table_card.actionButtons.selectAll);
        }

        /**
         *
         */
        function addColDefForLastCol() {

            var colDefLastIndex = -1;
            var defaultContent = "";

            // If the user has supplied columnDefs for last column, we ignore ours
            if (options && options.columnDefs) {

                // Check if user supplied columnDef for first column
                colDefLastIndex = options.columnDefs.findIndex(function(element){
                    return (element.targets === -1);
                });
            }

            // If caller did not supply columnDef for last column, we'll use our own
            if (colDefLastIndex === -1 && (
                settings.table_card.actionButtons.viewAction ||
                settings.table_card.actionButtons.editAction ||
                settings.table_card.actionButtons.deleteAction)) {

                overloadLastCol = true;
                defaultContent += settings.table_card.actionButtons.viewAction ?
                    "<a href='#' class='btn btn-white btn-xs row-view'><i class='fa fa-eye'></i>&nbsp;View</a>" :
                    "";

                defaultContent += settings.table_card.actionButtons.editAction ?
                    "<a href='#' class='btn btn-white btn-xs row-edit'><i class='fa fa-pencil'></i>&nbsp;Edit</a>" :
                    "";
                defaultContent += settings.table_card.actionButtons.deleteAction ?
                    "<a href='#' class='btn btn-white btn-xs row-delete'><i class='fa fa-trash'></i>&nbsp;Delete</a>" :
                    "";

                settings.columnDefs.push({
                    targets: -1,
                    data: null,
                    defaultContent: defaultContent
                });
            }

            return colDefLastIndex === -1;
        }

        /**
         *
         */
        function flip() {
            $card.toggleClass("flip");
        }

        /**
         *
         */
        function selectAllVisibleRows(select)
        {
            var table = $dataTable.rows();
            if (table) {
                for (var i = 0; i < table.count(); i++) {
                    var row = table.row(i);
                    if (row) {
                        var node = row.node();
                        if (node) {
                            var $checkbox = $(node).find(".select-row");
                            $checkbox ? $checkbox.iCheck(select ? 'check' : 'uncheck'): 0;
                        }
                    }
                }
            }
        }

        /**
         *
         */
        function handleDataTableDraw()
        {
            if (overloadFirstCol) {
                $('input.select-row').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green'
                });
                selectAllVisibleRows($selectAll.prop('checked'));
            }
            if (settings.batchMenu.enabled) {
                $btnBatchActions.prop("disabled", $dataTable.rows('.selected').count() ? false : true);
            }
        }

        /**
         *
         */
        function handleSelectRow(event)
        {
            event.preventDefault();
            event.stopImmediatePropagation();
            var rowSelector = $(this).parents('tr');
            if (rowSelector) {
                if (this.checked && !$(rowSelector).hasClass("selected")) {
                    $(rowSelector).addClass("selected");
                }
                else if (!this.checked && $(rowSelector).hasClass("selected")) {
                    $(rowSelector).removeClass("selected");
                }
            }

            if (settings.batchMenu.enabled) {
                $btnBatchActions.prop("disabled", $dataTable.rows('.selected').count() ? false : true);
            }
        }

        /**
         *
         */
        function handleBatchMenuItemClick(event)
        {
            var menuItem = $(this).children('a').attr('id');
            var selRows = $dataTable.rows('.selected');
            if (selRows.count()) {
                var data = selRows.data();
                settings.onBatchMenuItemClicked.call(this, event, menuItem, data);
            }
        }

        function defaultMenu() {
            return {
                deleteItems: { // The "delete" menu item
                    label: "Delete",
                    icon: "far fa-lg fa-trash"
                }
            };
        }

        /**
         *
         */
        function hookupEvents() {

            if (settings.table_card.actionButtons.newAction) {
                $newAction.click(function(event){
                    settings.onNewRowClicked();
                });
            }

            if (overloadFirstCol) {
                $dataTable.on('draw.dt', function(event){
                    handleDataTableDraw.call(this, event);
                });

                $dataTable.on('ifChanged', 'input.select-row', function(event){
                    handleSelectRow.call(this, event);
                });

                $selectAll.on('ifChanged', function(){
                    selectAllVisibleRows(this.checked);
                });
            }

            if (overloadLastCol) {
                // View Action Handler
                if (settings.table_card.actionButtons.viewAction) {
                    $dataTable.on('click', 'a.row-view', function (event) {
                        var rowSelector = $(this).parents('tr');
                        if (rowSelector) {
                            var data = $dataTable.row(rowSelector).data();
                            var index = $dataTable.row(rowSelector).index();
                            if (data && index >= 0) {
                                settings.onViewRowClicked(event, data, index);
                            }
                        }
                    });
                }

                // Edit Action Handler
                if (settings.table_card.actionButtons.editAction) {
                    $dataTable.on('click', 'a.row-edit', function (event) {
                        var rowSelector = $(this).parents('tr');
                        if (rowSelector) {
                            var data = $dataTable.row(rowSelector).data();
                            var index = $dataTable.row(rowSelector).index();
                            if (data && index >= 0) {
                                settings.onEditRowClicked(event, data, index);
                            }
                        }
                    });
                }

                // Delete Action Handler
                if (settings.table_card.actionButtons.deleteAction) {
                    $dataTable.on('click', 'a.row-delete', function (event) {
                        var rowSelector = $(this).parents('tr');
                        if (rowSelector) {
                            var data = $dataTable.row(rowSelector).data();
                            var index = $dataTable.row(rowSelector).index();
                            if (data && index >= 0) {
                                settings.onDeleteRowClicked(event, data, index);
                            }
                        }
                    });
                }
            }

            if (settings.batchMenu.enabled) {
                $batchMenu.on('click', 'li', function(event) {
                    event.preventDefault();
                    handleBatchMenuItemClick.call(this, event);
                });
            }
        }

        /**
         *
         */
        function populateBatchMenu()
        {
            var items = settings.batchMenu.items();
            var menuItemHtml = "";
            var menuHtml = "<div class='btn-group pull-left m-r-md'>   \
                                <button data-toggle='dropdown' id='btn-batch-actions' class='btn btn-default dropdown-toggle' type='button' aria-expanded='false'>\
                                    Batch Action(s)&nbsp;&nbsp;<span class='caret'></span>    \
                                </button>   \
                                <ul class='dropdown-menu'>  \
                                </ul>   \
                            </div>";

            if (settings.batchMenu.enabled) {
                $batchActions = $(".datatable-batch-actions");
                $batchActions.html(menuHtml);
                $batchMenu = $batchActions.find("ul.dropdown-menu");
                $btnBatchActions = $batchActions.find(settings.domSelectors.btnBatchActions);
                for (var property in items) {
                    if (items.hasOwnProperty(property)) {
                        var item = items[property];
                        menuItemHtml = menuItemHtml.concat("<li><a id='" + property + "' href='#'>");
                        if (item.icon !== "") {
                            menuItemHtml = menuItemHtml.concat("<i class='fa " + item.icon + "'></i>&nbsp;&nbsp;");
                        }
                        menuItemHtml = menuItemHtml.concat(item.label + "</a></li>");
                    }
                }
                $batchMenu.append(menuItemHtml);
            }
        }

        /**
         *
         */
        function init()
        {
            settings = $.extend({}, $.fn.table_card.defaults, options);
            $card = this.card({
                onInit: function() {
                    $frontFace = this.find(".tr-card-face.front");
                    $backFace  = this.find(".tr-card-face.back");
                    $dataTable = this.find(settings.domSelectors.dataTable);
                    $selectAll = this.find(settings.domSelectors.checkSelectAll);
                    $newAction = this.find(settings.domSelectors.new);

                    if ($dataTable === undefined) {
                        throw "Invalid dataTable id specified";
                    }

                    overloadFirstCol = addColDefForFirstCol();
                    overloadLastCol = addColDefForLastCol();

                    $dataTable = $dataTable.DataTable({
                        paging:         settings.paging,
                        sort:           settings.sort,
                        order:          settings.order,
                        dom:            settings.dom,
                        searching:      settings.searching,
                        search: {
                            regex:      settings.search.regex
                        },
                        stateSave:      settings.stateSave,
                        stateDuration:  settings.stateDuration,
                        deferRender:    settings.deferRender,
                        processing:     settings.processing,
                        serverSide:     settings.serverSide,
                        pagingType:     settings.pagingType,
                        select: {
                            style:      settings.select.style
                        },
                        ajax: {
                            url:        settings.ajax.url,
                            dataSrc:    settings.ajax.dataSrc
                        },
                        columns:        settings.columns,
                        columnDefs:     settings.columnDefs
                    });

                    populateBatchMenu();
                    hookupEvents();
                    settings.onInit.call();
                }
            });
        }

        /**
         *
         */
        $.fn.table_card.flip = flip;

        /**
         *
         */
        $.fn.table_card.dataTable = function() {
            return $dataTable;
        };

        /**
         * The defaults for the plugin
         */
        $.fn.table_card.defaults = {
            domSelectors: {
                dataTable:          "",
                checkSelectAll:     "",
                new:                "",
                btnBatchActions:    "#btn-batch-actions"
            },
            dom:                    '<"datatable-batch-actions">lfrtip',
            paging:                 true,
            sort:                   true,
            order:                  [[0, "asc"]],
            searching:              true,
            search: {
                regex:              false
            },
            stateSave:              true,
            stateDuration:          60 * 60 * 24,
            deferRender:            true,
            processing:             true,
            serverSide:             true,
            pagingType:             "full_numbers",
            select: {
                style:              "multiple"
            },
            ajax: {
                url:                "",
                dataSrc:            ""
            },
            columns:                [],
            columnDefs:             [],
            batchMenu: {
                enabled:            true,
                items:              defaultMenu
            },
            table_card: {
                actionButtons: {
                    newAction:      true,
                    selectAll:      true,
                    viewAction:     true,
                    editAction:     true,
                    deleteAction:   true
                },
                batchMenu: {
                    enabled:            true,
                    items:              defaultMenu
                }
            },
            onInit:                 function()                      {},
            onNewRowClicked:        function()                      {},
            onViewRowClicked:       function(event, data, index)    {},
            onEditRowClicked:       function(event, data, index)    {},
            onDeleteRowClicked:     function(event, data, index)    {},
            onBatchMenuItemClicked: function(event, menuItem, data) {},
        };

        // 'this' here in the plugin context is jQuery Object
        return this.each(function(){
            // 'this' here in the callback is the raw DOM element
            init.call($(this));
        });
    };

}(jQuery));

