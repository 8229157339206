<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-content p-md">
                    <ajax-table :api="api" :options="options">

                    </ajax-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'api'
        ],

        data: function () {

            return {
                startPos: 0,
                pageSize: 20,
            }
        },

        computed: {

            options() {
                var self = this;
                return  {
                    start: this.startPos,
                    length: this.pageSize,
                    sort_column: 'name',
                    sort_order: "ASC",
                    columns: [
                        { header: 'Name', field: 'name', sortable: true },
                        {
                            header: 'Rate',
                            field: 'rate',
                            sortable: true,
                            render: function (row, col) {
                                return self.getTaxRate(row, col);
                            }
                        },
                    ],
                    ajax: {
                        url: "/taxes",
                        dataSrc: "data",
                    }
                };
            },
        },

        methods: {
            getTaxRate(row, col) {
                return (col / row['multiplier']).toFixed(2) + '%';
            },
        },

        mounted() {
        },

        created() {
        }
    }
</script>