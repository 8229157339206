export const apiConstants = {
    fetchZones: '/reservations/zones',
    fetchTableStatuses: '/reservations/tables',
    fetchSlotAvailability: '/reservations/free-slots',
    fetchSessions: '/time/sessions',
    fetchSessionsForDate: '/reservations/sessions',
    reservations: {
        slots: '/reservations/timeslots',
        createSession: '/reservations/sessions',
        deleteSession: '/reservations/sessions',
        updateDayOfWeekSlots: '/reservations/sessions',
        fetchTables: '/reservations/tables',
        deleteTable: '/reservations/tables',
        updateTable: '/reservations/tables',
        fetchBookings: '/reservations'
    }
};