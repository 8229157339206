
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./_bootstrap');

// 3rd Party
require('jquery-slimscroll');
require('metismenu');
require('icheck-bootstrap');
window.moment = require('moment');
require('../../vendor/inspinia_admin_theme/js/inspinia.js');

// Application Plugins
require('../../shared/components/jquery-card/jquery.card.js');
require('../../shared/components/jquery-card/jquery.table_card.js');

// Load common code
require('../../shared/components/form/form-errors');
require('../../shared/components/form/form');

require('./_api');

import * as Vue from 'vue';
window.Vue = Vue;
import vSelect from "vue-select";

// Views for whole pages. These will be available only in top level views.
var CoaView = require('../../coa/vue/CoaView.vue').default;
var TaxView = require('../../tax/vue/TaxView.vue').default;

// The Vue Modules
// Vue.component('module-reservation',     require('./vue-modules/Reservation.vue').default);
