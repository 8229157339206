// Load our api

let apiAccounts = require('../../shared/api/accounts');
let apiParty = require('../../shared/api/party');
import { apiTime } from "../../shared/api/time";
import { apiBookings } from "../../shared/api/bookings";
import { apiNotifications} from "../../shared/api/notifications";

let api = {
    party: apiParty,
    accounts: apiAccounts,
    time: apiTime,
    reservation: apiBookings,
    notification: apiNotifications
};

window.api = api;
