var apiAccounts = {

    helloThere: function () {
        console.log("Hello from standalone mixin");
    },

    fetchMajorTypes() {
        console.log("fetchMajorTypes(): fetching via. AJAX");
        return $.getJSON('/accounts/major_types');
    },

    fetchMinorTypes() {
        console.log("fetchMinorTypes(): fetching via. AJAX");
        return $.getJSON('/accounts/minor_types');
    },

    fetch(accountType = null) {
        console.log("fetchAccounts(): fetching via. AJAX");
        return $.getJSON('/accounts' + (accountType ? ('major_type=' + accountType) : ""));
    }
};

module.exports = apiAccounts;