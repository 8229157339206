<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-content p-md">
                    <ajax-table :api="api" :options="options" v-on:lineItemClick="onAccountClicked">

                        <div class="btn-group btn-group-sm m-b-md">
                            <button class="btn btn-white" type="button"
                                    :class="{ active: isActive('') }"
                                    @click="setActive('')">All Accounts</button>

                            <button class="btn btn-white" type="button"
                                    :class="{ active: isActive('asset') }"
                                    @click="setActive('asset')">Assets</button>

                            <button class="btn btn-white" type="button"
                                    :class="{ active: isActive('liability') }"
                                    @click="setActive('liability')">Liabilities</button>

                            <button class="btn btn-white" type="button"
                                    :class="{ active: isActive('equity') }"
                                    @click="setActive('equity')">Equity</button>

                            <button class="btn btn-white" type="button"
                                    :class="{ active: isActive('expense') }"
                                    @click="setActive('expense')">Expenses</button>

                            <button class="btn btn-white" type="button"
                                    :class="{ active: isActive('revenue') }"
                                    @click="setActive('revenue')">Revenue</button>

                            <button class="btn btn-white" type="button"
                                    @click="showAccountModal = !showAccountModal">Show Panel</button>
                        </div>

                    </ajax-table>
                </div>
            </div>
        </div>

        <modal v-if="showAccountModal" class="white-bg" @close="showAccountModal = false">

            <!-- Modal Header -->
            <div slot="header">
                <h3>Account</h3>
            </div>

            <!-- Modal Body -->
            <div slot="body">
                <form role="form">
                    <div class="row">

                        <div class="col-sm-6">

                            <div class="form-group" :class="{'has-error': accountForm.errors.has('major_type')}">
                                <label for="major_type" class="control-label">Major Type</label>
                                <select class="form-control"
                                        name="major_type"
                                        id="major_type"
                                        v-model="accountForm.major_type">
                                    <option v-for="majorType in majorTypes" v-bind:value="majorType.type">{{majorType.name}}</option>
                                </select>
                            </div>

                            <div class="form-group" :class="{'has-error': accountForm.errors.has('minor_type')}">
                                <label for="minor_type" class="control-label">Minor Type</label>
                                <select class="form-control"
                                        name="minor_type"
                                        id="minor_type"
                                        v-model="accountForm.minor_type">
                                    <option v-for="minorType in supportedMinorTypes" v-bind:value="minorType.minor_type">{{minorType.name}}</option>
                                </select>
                            </div>

                            <div class="form-group" :class="{'has-error': accountForm.errors.has('description')}">
                                <label for="description" class="control-label">Description</label>
                                <textarea class="form-control"
                                          name="description"
                                          id="description"
                                          readonly
                                          v-model="accountForm.description">
                                </textarea>
                            </div>

                        </div>

                        <div class="col-sm-6">

                            <div class="form-group" :class="{'has-error': accountForm.errors.has('name')}">
                                <label for="name" class="control-label">Name</label>
                                <input type="text"
                                       class="form-control"
                                       name="name" id="name"
                                       v-model="accountForm.name">
                            </div>

                            <div class="form-group" :class="{'has-error': accountForm.errors.has('description')}">
                                <label for="description" class="control-label">Description</label>
                                <input type="text"
                                       class="form-control"
                                       name="description"
                                       id="description"
                                       v-model="accountForm.description">
                            </div>

                            <div class="form-group">
                                <div class="checkbox">
                                    <label for="is_child" class="control-label">
                                        <input type="checkbox" name="is_child" id="is_child"> Is a sub-account
                                    </label>
                                </div>
                                <select class="form-control" name="parent" id="parent"></select>
                            </div>

                            <div class="form-group">
                                <label for="tax" class="control-label">Default Tax</label>
                                <select class="form-control" name="tax" id="tax"></select>
                            </div>

                        </div>

                    </div>
                </form>
            </div>

            <!-- Modal Footer -->
            <div slot="footer">
                <div class="row">
                    <div class="col-sm-12">
                        <button type="submit" class="btn btn-w-m btn-primary pull-right">Save</button>
                        <button type="button" class="btn btn-w-m btn-default pull-right m-r-xs"
                                @click="showAccountModal = false">Cancel</button>
                    </div>
                </div>
            </div>

        </modal>

    </div>
</template>

<script>
    var Form = require('../../shared/components/form/form').default;
    export default {

        props: [
            'api'
        ],

        data: function () {

            return {
                startPos: 0,
                pageSize: 20,
                account_type: "",

                showAccountModal: false,
                accountForm: {},

                majorTypes: [],
                minorTypes: []
            }
        },

        computed: {
            options() {
                var self = this;
                return  {
                    start: this.startPos,
                    length: this.pageSize,
                    sort_column: 'name',
                    sort_order: "ASC",
                    columns: [
                        { header: 'Code', field: 'code'},
                        { header: 'Name', field: 'name', sortable: true},
                        { header: 'Description', field: 'description'},
                        {
                            header: 'Type',
                            field: 'major_type',
                            sortable: false,
                            render: function (row, col) {
                                return self.accountTypeDisplayName(col);
                            }
                        },
                        { header: 'Actions', field: ''},
                    ],
                    ajax: {
                        url: "/accounts",
                        dataSrc: "data",
                        params: {
                            major_type: this.account_type
                        }
                    }
                };
            },

            supportedMinorTypes() {
                return this.minorTypes.filter(minorType => minorType.major_type == this.accountForm.major_type);
            }
        },

        methods: {
            onAccountClicked(account) {
                this.accountForm = new Form(account);
                this.showAccountModal = true;
            },

            accountTypeDisplayName(asset) {
                switch (asset) {
                    case "asset":
                        return "Asset";
                    case "liability":
                        return "Liability";
                    case "equity":
                        return "Equity";
                    case "expense":
                        return "Expense";
                    case "revenue":
                        return "Revenue";
                    default:
                        return "Unknown";
                }
            },

            isActive(type) {
                return this.account_type == type;
            },

            setActive(type) {
                this.account_type = type;
            }
        },

        mounted() {
        },

        created() {
            this.api.accounts.fetchMajorTypes().then(data => this.majorTypes = data.data);
            this.api.accounts.fetchMinorTypes().then(data => this.minorTypes = data.data);
        }
    }
</script>