import { httpClient } from '../components/http-client/http-client';
import { apiConstants } from "./api-constants";

var apiBookings = {

    fetchTables(zoneId = 0) {
        let params = {
            zone_id: zoneId
        };
        return httpClient.get(apiConstants.reservations.fetchTables, {params: params});
    },

    fetchZones() {
        return httpClient.get(apiConstants.fetchZones);
    },

    fetchTableStatuses(fromDate, toDate, sessionIds, zoneIds) {
        let params = {
            from_date: fromDate,
            to_date: toDate,
            sessions: sessionIds,
            zones: zoneIds
        };
        return httpClient.get(apiConstants.fetchTableStatuses, { params: params });
    },

    fetchSlotAvailability(date, pax = null, session = null) {
        let params = {
            date: date,
        };
        if (pax !== null) {
            params.pax = pax;
        }
        if (session !== null) {
            params.session = session;
        }
        return httpClient.get(apiConstants.fetchSlotAvailability, { params: params });
    },

    deleteTable(tableId) {
        return httpClient.delete(`${apiConstants.reservations.deleteTable}/${tableId}`);
    },

    updateTable(tableId, table) {
        return httpClient.put(`${apiConstants.reservations.updateTable}/${table.id}`, table);
    },

    fetchBookings(date) {
        let params = {
            date: date
        };
        return httpClient.get(apiConstants.reservations.fetchBookings, {params: params});
    }

};

export { apiBookings };