let $elErrorToast = null;
let $elHeader = null;
let $elButton = null;
let $elTitle = null;
let $elDescription = null;

// Let's get the error toast element ready
$(document).ready(function(){
    $elErrorToast = $('.error-toast');
    if ($elErrorToast) {
        $elHeader = $elErrorToast.find('.toast-header');
        $elTitle = $elErrorToast.find('.toast-header #title');
        $elButton = $elHeader.find('.close');
        $elDescription = $elErrorToast.find('.toast-body');
        $elErrorToast.toast({
            delay: 5000,
            autohide: false,
            animation: true
        });
    }
});

function processError_HTTP_UNPROCESSABLE_ENTITY(response) {
    if (response.data && response.data.errors) {
        let description = "<ul>";
        Object.values(response.data.errors).forEach(value => {
            description += "<li>" + value[0] + "</li>";
        });
        description += "</ul>";
        return description;
    }
    else if (response.data && response.data.message) {
        return response.data.message;
    }
    else {
        return response.statusText ? response.statusText : "Unknown Error";
    }
}

/**
 * @param response
 * @returns {string}
 */
function processError_INTERNAL_SERVER_ERROR(reason) {
    let message = reason.statusText;
    if (reason.hasOwnProperty("data")) {
        if (reason.data.hasOwnProperty("error")) {
            if (reason.data.error.hasOwnProperty("message")) {
                message = reason.data.error.message;
            }
        }
    }
    return message;
}

const RED = "#ed5565";
const WHITE = "#fff";

var apiNotifications = {
    setErrorColours() {
        $elErrorToast.css("background-color", RED);
        $elButton.css("color", WHITE);
        $elHeader.css("color", WHITE);
        $elHeader.css("background-color", RED);
    },
    showErrorToastForRejectedPromise(title, reason) {
        let description = "";
        switch (reason.status) {
            case 422: /*Response::HTTP_UNPROCESSABLE_ENTITY:*/
                description = processError_HTTP_UNPROCESSABLE_ENTITY(reason);
                break;
            case 500:
                description = processError_INTERNAL_SERVER_ERROR(reason);
                break;
            default:
                description = reason.statusText;
        }
        this.showErrorToast(title, description);
    },

    showErrorToast(title, description) {
        this.setErrorColours();
        $elTitle.html(title);
        $elDescription.html(description);
        $elErrorToast.toast('show');
    }
};

export { apiNotifications }