require('./bootstrap/js/_app');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({

    el: '#wrapper',

    data: function () {

        return {
            api: window.api,
        }
    },

    created: function () {
    },

    methods: {
    },

    components: {
    }

});

Window.vueApp = app;